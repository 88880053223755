// project logos
import z2_logo from '../image/logos/Z2_LOGO.png'
import z_luxuria_logo from '../image/logos/ZADE_LUXURIA_LOGO.png';

// project images
import z2_image from '../image/z2/8.jpeg';
import zluxuria_image from '../image/zluxuria/Zluxuria1.jpeg';

// z luxuria images
import amenities_img2 from '../image/zluxuria/zluxuria_specification2.jpeg'
import amenities_img3 from '../image/zluxuria/zluxuria_specification3.jpeg'
import amenities_img4 from '../image/zluxuria/zluxuria_specification4.jpeg'
import amenities_img5 from '../image/zluxuria/zluxuria_specification5.jpeg'
import amenities_img8 from '../image/zluxuria/zluxuria_specification8.jpeg'
import amenities_img9 from '../image/zluxuria/zluxuria_specification9.jpg'
import amenities_img10 from '../image/zluxuria/Zluxuria6.jpeg'

import icon1 from '../image/icons/view.webp';
import icon2 from '../image/icons/natural-light.webp';
import icon3 from '../image/icons/villaments.webp';
import icon4 from '../image/icons/balcony-view-swing.webp';


// amenities icons
import Zade_Luxuria_amenities1 from '../image/icons/zluxuria/hall.webp';
import Zade_Luxuria_amenities2 from '../image/icons/zluxuria/4_level_basement_parking.webp';
import Zade_Luxuria_amenities4 from '../image/icons/zluxuria/Security_cabin.webp';
import Zade_Luxuria_amenities5 from '../image/icons/zluxuria/Society_Maintenace_office.webp';
import Zade_Luxuria_amenities6 from '../image/icons/zluxuria/Guest_room.webp';
import Zade_Luxuria_amenities7 from '../image/icons/zluxuria/elevator.webp';
import Zade_Luxuria_amenities8 from '../image/icons/zluxuria/adjoining_deck.webp';
import Zade_Luxuria_amenities9 from '../image/icons/zluxuria/tv_lounge.webp';
import Zade_Luxuria_amenities10 from '../image/icons/zluxuria/kitty_party_room.webp';
import Zade_Luxuria_amenities11 from '../image/icons/zluxuria/pool_table.webp';
import Zade_Luxuria_amenities12 from '../image/icons/zluxuria/Toddler_s_play_Zone.webp';
import Zade_Luxuria_amenities13 from '../image/icons/zluxuria/board-game.webp';
import Zade_Luxuria_amenities14 from '../image/icons/zluxuria/swimming-pool.webp';
import Zade_Luxuria_amenities15 from '../image/icons/zluxuria/gym.webp';
import Zade_Luxuria_amenities16 from '../image/icons/zluxuria/salon-spa.webp';
import Zade_Luxuria_amenities17 from '../image/icons/zluxuria/Theater.webp';
import Zade_Luxuria_amenities18 from '../image/icons/zluxuria/Open_Terrace.webp';
import Zade_Luxuria_amenities19 from '../image/icons/zluxuria/generator.webp';
import Zade_Luxuria_amenities20 from '../image/icons/zluxuria/Garbage-duct-system.webp';
import Zade_Luxuria_amenities21 from '../image/icons/zluxuria/air-conditioner.webp';

import Z2_amenities1 from '../image/icons/z2/entrance_plaza.webp'
import Z2_amenities2 from '../image/icons/z2/conference-room.webp'
import Z2_amenities3 from '../image/icons/z2/reception-area-hall.webp'
import Z2_amenities4 from '../image/icons/z2/admin-office.webp'
import Z2_amenities5 from '../image/icons/z2/driver-waiting-lounge.webp'
import Z2_amenities6 from '../image/icons/z2/security-cabin.webp'
import Z2_amenities7 from '../image/icons/z2/5-level-basement-parking.webp'
import Z2_amenities8 from '../image/icons/z2/wide-drive-way.webp'
import Z2_amenities9 from '../image/icons/z2/elevators.webp'



export const Projects = [
    {
        title: "Z2",
        logo: z2_logo,
        id: "01",
        description: "The Z2, Corporate Space is our newest project in construction, offering a state-of-the-art space for businesses and corporations to thrive. This stunning 32-story building boasts a grand height of 11 feet per floor, providing space for businesses of all sizes",
        location: "Thaltej, Ahmedabad",
        locationLink: "https://goo.gl/maps/BK2JuYFx4JkdacXRA",
        status: "Under Construction",
        link: "/z2",
        image: z2_image,
        details: [
            {
                0: 32,
                1: "Stories"
            },
            {
                0: 1230,
                1: "Sq. Ft. Onwards"
            },
            {
                0: 5,
                1: "Level well-planed basement parking"
            },
            {
                0: 2,
                1: "Side road corners"
            },
        ],
        moreDetails: [
            {
                0: 32,
                1: "Stories"
            },
            {
                0: 1230,
                1: "Sq. Ft. Onwards"
            },
            {
                0: 5,
                1: "Level well-planed basement parking"
            },
            {
                0: 2,
                1: "Side road corners"
            },
            {
                0: 11,
                1: "Ft. Clear Height"
            },

        ],
    },
    {
        title: "Z Luxuria",
        logo: z_luxuria_logo,
        id: "02",
        description: "Introducing our latest project, Z Luxuria a luxurious residential tower that will set new standards for opulent living in Ahmedabad. This magnificent 33-story tower offers 56 units with world-class amenities and stunning architectural design.",
        location: "Thaltej, Ahmedabad",
        locationLink: "https://goo.gl/maps/Zsd2pVJPmeursQRY9",
        status: "Under Construction",
        link: "/zluxuria",
        image: zluxuria_image,
        details: [
            {
                0: 33,
                1: "Stories"
            },
            {
                0: 56,
                1: "Units 2 Villaments Per Floor"
            },
            {
                0: "4, 6 & 7 BHK",
                1: "Supersize Residency"
            },
            {
                0: 4,
                1: "Level Lavish Parking"
            },
        ],
        moreDetails: [
            {
                0: "12.25",
                1: "Ft. Clear Height"
            },
            {
                0: "21842",
                1: "Sq. Ft. Landscape Garden"
            },
            // {
            //     0: "Double",
            //     1: "Height reception & Waiting lounge"
            // },
            {
                0: "145",
                1: "Meter high viewing deck"
            },
        ],
        aboutDetails: [
            {
                "icon": icon1,
                "title": "A 270 degree view",
                "description": "A 147 meters tall tower amidst low rise buildings gilts you a rare chance to witness the spellbinding beauty that lay in front of you."
            },
            {
                "icon": icon2,
                "title": "Abundant natural light",
                "description": "The strategic placement of the tower and large windows ensure abundant natural light and air ventilation."
            },
            {
                "icon": icon3,
                "title": "Only 2 Villament per floor",
                "description": "The privacy, exclusivity, richness and freedom of space you experience is amazing when there are only 2 apartments on each floor, A 12.25 feet clear height is like a bonus."
            },
            {
                "icon": icon4,
                "title": "Spacious Balcony",
                "description": "These large viewing decks is the perfect spot to enjoy delightful conversations, your favourite books or a hot cup of coffee. It attaches the feel of openness and spaciousness to your apartment."
            },
        ]

    }
];


export const Amenities = {
    "zluxuria": [
        //     { "SVG": <ImEnter size={80} style={{ 'fill': "#CCA34B" }} />, "name": "DOUBLE HEIGHT ENTRANCE FOYER" },
        //     { "SVG": <TbArmchair size={80} style={{ 'stroke': "#CCA34B" }} />, "name": "DOUBLE HEIGHT WAITING & RECEPTION AREA" },
        //     { "SVG": <TfiLayoutMediaCenter size={80} style={{ 'fill': "#CCA34B" }} />, "name": "DOUBLE HEIGHT OWNER'S LOUNGE" },
        {
            "SVG": Zade_Luxuria_amenities1, "name": "Double Height Multipurpose Hall"
        },
        { "SVG": Zade_Luxuria_amenities2, "name": "4 Level basement parking" },
        // { "SVG": { Zade_Luxuria_amenities3 }, "name": "METER ROOM" },
        { "SVG": Zade_Luxuria_amenities4, "name": "Security Cabin" },
        { "SVG": Zade_Luxuria_amenities5, "name": "Society Maintenance Office" },
        { "SVG": Zade_Luxuria_amenities6, "name": "2 Guest Room" },
        { "SVG": Zade_Luxuria_amenities7, "name": "4 High Speed Elevators" },
        { "SVG": Zade_Luxuria_amenities8, "name": "Adjoining Deck" },
        { "SVG": Zade_Luxuria_amenities9, "name": "TV Lounge" },
        { "SVG": Zade_Luxuria_amenities10, "name": "Kitty Party Room" },
        { "SVG": Zade_Luxuria_amenities11, "name": "Indoor Games Area" },
        { "SVG": Zade_Luxuria_amenities12, "name": "Toddler's Play Zone" },
        { "SVG": Zade_Luxuria_amenities13, "name": "Board Games Room" },
        { "SVG": Zade_Luxuria_amenities14, "name": "Swimming Pool" },
        { "SVG": Zade_Luxuria_amenities15, "name": "GYM" },
        { "SVG": Zade_Luxuria_amenities16, "name": "Salon / Spa" },
        { "SVG": Zade_Luxuria_amenities17, "name": "Theater" },
        { "SVG": Zade_Luxuria_amenities18, "name": "Open Terrace With Sitting" },
        { "SVG": Zade_Luxuria_amenities19, "name": "D.G. Set ( For Emergency Use )" },
        { "SVG": Zade_Luxuria_amenities20, "name": "Garbage Duct Facilities" },
        { "SVG": Zade_Luxuria_amenities21, "name": "VRV Outdoor Unit" }
    ],
    "Z2": [

        { "SVG": Z2_amenities1, "name": "Entrance Plaza" },
        { "SVG": Z2_amenities2, "name": "Double Height Conference Room" },
        { "SVG": Z2_amenities3, "name": "Double Height Reception & Waiting Area" },
        { "SVG": Z2_amenities4, "name": "Admin Office " },
        { "SVG": Z2_amenities5, "name": "Driver Waiting Lounge" },
        { "SVG": Z2_amenities6, "name": "Security Cabin" },
        { "SVG": Z2_amenities7, "name": "5 level Basement Parking" },
        { "SVG": Z2_amenities8, "name": "Wide Drive Way" },
        // { "SVG": <IoBedOutline size={50} style={{ stroke: '#cda24b' }} />, "name": "2 Guest Room  " },
        { "SVG": Z2_amenities9, "name": "5 High Speed Elevators" },
        // { "SVG": <GiPowerGenerator size={50} style={{ fill: '#cda24b' }} />, "name": "Stan dby DG Set For Emergency " },
        // { "SVG": <BsCloudRain size={50} style={{ fill: '#cda24b' }} />, "name": "Rain Water Storage" },
    ]
}

export const AmenitiesDetails = {
    "zluxuria": [
        {
            "svg": "",
            "image": amenities_img2,
            "name": "Home Theater",
            "description": "Experience cinema-quality visuals and sound, immersing you in movie magic without leaving the comfort of our residency.",
        },
        {
            "svg": "",
            "image": amenities_img3,
            "name": "Toddler Play Zone",
            "description": "A safe and vibrant space for the little ones to unleash their imagination, ensuring fun-filled and constructive playtime.",
        },
        {
            "svg": "",
            "image": amenities_img4,
            "name": "TV Lounge",
            "description": "A cozy retreat where residents can gather, relax, and catch up on their favorite shows or the latest news, fostering community ties.",
        },
        {
            "svg": "",
            "image": amenities_img5,
            "name": "Indoor Game Area",
            "description": "Engage in friendly competition or hone your billiards skills, offering residents both leisure and entertainment in one corner.",
        },
        {
            "svg": "",
            "image": amenities_img9,
            "name": "GYM and Yoga Area",
            "description": "The gym is equipped with cutting-edge equipment to help you achieve your fitness goals, while the yoga area provides a serene space for meditation and yoga practice.",
        },
        {
            "svg": "",
            "image": amenities_img8,
            "name": "Infinity Swimming Pool",
            "description": "A covered pool promising you a refreshing dip in the water along with gorgeous views of the horizon that are sure to soothe your mind, bod and soul",
        },
        {
            "svg": "",
            "image": amenities_img10,
            "name": "Terrace Garden",
            "description": "The charming sit-outs and decks wrapped in fragrant plantations, under the mesmerizing sky makes for the picture perfect space for party and gatherings.",
        },

    ]
}

export const RERA = {
    z2: "PR/GJ/AHMEDABAD/AHMEDABADCITY/AUDA/CAA12096/060723",
    zluxuria: "PR/GJ/AHMEDABAD/AHMEDABADCITY/AUDA/RAA12017/280623",
    RERAWEB: "www.gujrerar1.gujarat.gov.in"
}